import { BrowserRouter, Routes, Route } from "react-router-dom";

import { Home } from "./Components/Home";
import { Imprint } from "./Components/Imprint";
import { PrivacyPolicy } from "./Components/PrivacyPolicy";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/impressum" element={<Imprint />} />
        <Route path="/datenschutz" element={<PrivacyPolicy />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
