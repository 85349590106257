import "../App.css";

export function PrivacyPolicy() {
  return (
    <div className="app app-header">
      <div className="privacy-policy-content">
        <h1>Datenschutzerklärung</h1>
        <h2>1) Allgemeine Hinweise</h2>
        <p>
          Die folgenden Hinweise geben einen einfachen Überblick darüber, was
          mit Ihren personenbezogenen Daten passiert, wenn Sie unsere Website
          besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie
          persönlich identifiziert werden können. Ausführliche Informationen zum
          Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten
          Datenschutzerklärung.
        </p>
        <h3>
          Wer ist verantwortlich für die Datenerfassung auf dieser Website?
        </h3>
        <p>
          Die Datenverarbeitung auf dieser Website erfolgt durch den
          Websitebetreiber. Dessen Kontaktdaten können Sie dem Impressum dieser
          Website entnehmen.
        </p>
        <h3>Wie erfassen wir Ihre Daten?</h3>
        <p>
          Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese
          mitteilen. Hierbei kann es sich z.B. um Daten handeln, die Sie in ein
          Kontaktformular eingeben.
        </p>
        <p>
          Andere Daten werden automatisch beim Besuch der Website durch unsere
          IT-Systeme erfasst. Das sind vor allem technische Daten (z.B.
          Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs). Die
          Erfassung dieser Daten erfolgt automatisch, sobald Sie unsere Website
          betreten.
        </p>
        <h3>Wofür nutzen wir Ihre Daten?</h3>
        <p>
          Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung
          der Website zu gewährleisten. Andere Daten können zur Analyse Ihres
          Nutzerverhaltens verwendet werden.
        </p>
        <h3>Welche Rechte haben Sie bezüglich Ihrer Daten?</h3>
        <p>
          Sie haben jederzeit das Recht unentgeltlich Auskunft über Herkunft,
          Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu
          erhalten. Sie haben außerdem ein Recht, die Berichtigung, Sperrung
          oder Löschung dieser Daten zu verlangen. Hierzu sowie zu weiteren
          Fragen zum Thema Datenschutz können Sie sich jederzeit unter der im
          Impressum angegebenen Adresse an uns wenden. Des Weiteren steht Ihnen
          ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
        </p>
        <p>
          Außerdem haben Sie das Recht, unter bestimmten Umständen die
          Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu
          verlangen. Details hierzu entnehmen Sie der Datenschutzerklärung unter
          „Recht auf Einschränkung der Verarbeitung“.
        </p>
        <h2>2) Allgemeine Hinweise und Pflichtinformationen Datenschutz</h2>
        <p>
          Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten
          sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und
          entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser
          Datenschutzerklärung.
        </p>
        <p>
          Wenn Sie diese Website benutzen, werden verschiedene personenbezogene
          Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie
          persönlich identifiziert werden können. Die vorliegende
          Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir
          sie nutzen. Sie erläutert auch, wie und zu welchem Zweck das
          geschieht.
        </p>
        <p>
          Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei
          der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein
          lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht
          möglich.
        </p>
        <h3>Hinweis zur verantwortlichen Stelle</h3>
        <p>Andreas Hald</p>
        <p> Marienstraße 32</p>
        <p>73479 Ellwangen</p>
        <p>
          Verantwortliche Stelle ist die natürliche oder juristische Person, die
          allein oder gemeinsam mit anderen über die Zwecke und Mittel der
          Verarbeitung von personenbezogenen Daten (z.B. Namen, E-Mail-Adressen
          o. Ä.) entscheidet.
        </p>
        <h3>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h3>
        <p>
          Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen
          Einwilligung möglich. Sie können eine bereits erteilte Einwilligung
          jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail
          an uns. Die Rechtmäßigkeit der bis zum Widerruf erfolgten
          Datenverarbeitung bleibt vom Widerruf unberührt.
        </p>
        <h3>
          Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie
          gegen Direktwerbung (Art. 21 DSGVO)
        </h3>
        <p>
          Wenn die Datenverarbeitung auf Grundlage von Art. 6 Abs. 1 lit. e oder
          f DSGVO erfolgt, haben Sie jederzeit das Recht, aus Gründen, die sich
          aus Ihrer besonderen Situation ergeben, gegen die Verarbeitung Ihrer
          personenbezogenen Daten Widerspruch einzulegen; dies gilt auch für ein
          auf diese Bestimmungen gestütztes Profiling. Die jeweilige
          Rechtsgrundlage, auf denen eine Verarbeitung beruht, entnehmen Sie
          dieser Datenschutzerklärung. Wenn Sie Widerspruch einlegen, werden wir
          Ihre betroffenen personenbezogenen Daten nicht mehr verarbeiten, es
          sei denn, wir können zwingende schutzwürdige Gründe für die
          Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten
          überwiegen oder die Verarbeitung dient der Geltendmachung, Ausübung
          oder Verteidigung von Rechtsansprüchen (Widerspruch nach Art. 21 Abs.
          1 DSGVO).
        </p>
        <p>
          Werden Ihre personenbezogenen Daten verarbeitet, um Direktwerbung zu
          betreiben, so haben Sie das Recht, jederzeit Widerspruch gegen die
          Verarbeitung Sie betreffender personenbezogener Daten zum Zwecke
          derartiger Werbung einzulegen; dies gilt auch für das Profiling,
          soweit es mit solcher Direktwerbung in Verbindung steht. Wenn Sie
          widersprechen, werden Ihre personenbezogenen Daten anschließend nicht
          mehr zum Zwecke der Direktwerbung verwendet (Widerspruch nach Art. 21
          Abs. 2 DSGVO).
        </p>
        <h3>Beschwerderecht bei der zuständigen Aufsichtsbehörde</h3>
        <p>
          Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein
          Beschwerderecht bei einer Aufsichtsbehörde, insbesondere in dem
          Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes
          oder des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht
          besteht unbeschadet anderweitiger verwaltungsrechtlicher oder
          gerichtlicher Rechtsbehelfe.
        </p>
        <h3>Recht auf Datenübertragbarkeit</h3>
        <p>
          Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung
          oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich
          oder an einen Dritten in einem gängigen, maschinenlesbaren Format
          aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten an
          einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es
          technisch machbar ist.
        </p>
        <h3>SSL- bzw. TLS-Verschlüsselung</h3>
        <p>
          Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der
          Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen oder
          Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL-bzw.
          TLS- Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie
          daran, dass die Adresszeile des Browsers von “http://” auf “https://”
          wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.
        </p>
        <p>
          Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die
          Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen
          werden.
        </p>
        <h3>Auskunft, Sperrung, Löschung und Berichtigung</h3>
        <p>
          Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit
          das Recht auf unentgeltliche Auskunft über Ihre gespeicherten
          personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck
          der Datenverarbeitung und ggf. ein Recht auf Berichtigung, Sperrung
          oder Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema
          personenbezogene Daten können Sie sich jederzeit unter der im
          Impressum angegebenen Adresse an uns wenden.
        </p>
        <h3>Recht auf Einschränkung der Verarbeitung</h3>
        <p>
          Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer
          personenbezogenen Daten zu verlangen. Hierzu können Sie sich jederzeit
          unter der im Impressum angegebenen Adresse an uns wenden. Das Recht
          auf Einschränkung der Verarbeitung besteht in folgenden Fällen:
        </p>
        <p>
          Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen
          Daten bestreiten, benötigen wir in der Regel Zeit, um dies zu
          überprüfen. Für die Dauer der Prüfung haben Sie das Recht, die
          Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu
          verlangen.Wenn die Verarbeitung Ihrer personenbezogenen Daten
          unrechtmäßig geschah / geschieht, können Sie statt der Löschung die
          Einschränkung der Datenverarbeitung verlangen.
        </p>
        <p>
          Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie
          jedoch zur Ausübung, Verteidigung oder Geltendmachung von
          Rechtsansprüchen benötigen, haben Sie das Recht, statt der Löschung
          die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu
          verlangen.Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO
          eingelegt haben, muss eine Abwägung zwischen Ihren und unseren
          Interessen vorgenommen werden. Solange noch nicht feststeht, wessen
          Interessen überwiegen, haben Sie das Recht, die Einschränkung der
          Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
        </p>
        <p>
          Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt
          haben, dürfen diese Daten – von ihrer Speicherung abgesehen – nur mit
          Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung
          von Rechtsansprüchen oder zum Schutz der Rechte einer anderen
          natürlichen oder juristischen Person oder aus Gründen eines wichtigen
          öffentlichen Interesses der Europäischen Union oder eines
          Mitgliedstaats verarbeitet werden.
        </p>
        <h3>Datenerfassung auf unserer Website Kontaktformular</h3>
        <p>
          Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre
          Angaben aus dem Anfrageformular inklusive der von Ihnen dort
          angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für den
          Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir
          nicht ohne Ihre Einwilligung weiter.
        </p>
        <p>
          Die Verarbeitung der in das Kontaktformular eingegebenen Daten erfolgt
          somit ausschließlich auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1
          lit. a DSGVO). Sie können diese Einwilligung jederzeit widerrufen.
          Dazu reicht eine formlose Mitteilung per E-Mail an uns. Die
          Rechtmäßigkeit der bis zum Widerruf erfolgten
          Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.
        </p>
        <h2>3) Verwendung von Cookies</h2>
        <p>Wir verwenden keine Cookies.</p>
        <h2>4) Tracking- und Analysetools</h2>
        <p>Wir verwenden keine Trackingtools.</p>
        <h2>5) Social Media Profile</h2>
        <p>
          Wir unterhalten verschiedene Social Media Profile, die in Teilen auf
          unseren Seiten verlinkt sind. Solange sich Nutzer auf dieser Website
          befinden, werden keinerlei Daten übertragen. Sofern sie sich aber
          entscheiden, unsere Profile zu besuchen, gelten die Bestimmungen der
          jeweiligen Anbieter. Wir weisen darauf hin, dass wir keinen Einfluss
          auf die dort erhobenen Daten haben und diese auch nicht nutzen.
        </p>
        <h3>Instagram</h3>
        <p>
          Instagram ist ein Facebookprodukt und ist Teil des Datennetzwerks.
        </p>
        <p>
          Weitere Informationen sind in der{" "}
          <a href="https://instagram.com/about/legal/privacy/">
            Datenschutzerklärung von Instagramm
          </a>{" "}
          zu finden.
        </p>
        <h3>Youtube</h3>
        <p>Youtube ist eine Videoplattform, die zu Google gehört.</p>{" "}
        <p>
          Weitere Informationen sind in der{" "}
          <a href="https://policies.google.com/privacy?hl=de">
            Datenschutzerklärung von Google
          </a>{" "}
          zu finden.
        </p>
        <h3>Spotify</h3>
        <p>
          Spotify ist ein digitaler Streamingdienst für Musik, Podcasts und
          Videos.
        </p>{" "}
        <p>
          Weitere Informationen sind in der{" "}
          <a href="https://www.spotify.com/de/legal/privacy-policy/">
            {" "}
            Datenschutzerklärung von Spotify.
          </a>{" "}
          zu finden.
        </p>
        <h2>6) Anfrage per E-Mail oder Telefon</h2>
        <p>
          Wenn Sie uns per E-Mail oder Telefon kontaktieren, wird Ihre Anfrage
          inklusive aller daraus hervorgehenden personenbezogenen Daten (Name,
          Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens bei uns
          gespeichert und verarbeitet. Diese Daten geben wir nicht ohne Ihre
          Einwilligung weiter.
        </p>
        <p>
          Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1
          lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags
          zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen
          erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf
          Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) und / oder auf unseren
          berechtigten Interessen (Art. 6 Abs. 1 lit. f DSGVO), da wir ein
          berechtigtes Interesse an der effektiven Bearbeitung der an uns
          gerichteten Anfragen haben.
        </p>
        <p>
          Die von Ihnen an uns per Kontaktanfragen übersandten Daten verbleiben
          bei uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur
          Speicherung widerrufen oder der Zweck für die Datenspeicherung
          entfällt (z. B. nach abgeschlossener Bearbeitung Ihres Anliegens).
          Zwingende gesetzliche Bestimmungen – insbesondere gesetzliche
          Aufbewahrungsfristen – bleiben unberührt.
        </p>
      </div>
    </div>
  );
}
